/** @jsx jsx */
import { jsx, Box, Button, Container, Flex, Styled, Text } from "theme-ui"
import { graphql } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"
import Slider from "react-slick"
import BackgroundImage from "gatsby-background-image"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from "@reach/accordion"
import "@reach/accordion/styles.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons"

import {
  Hero,
  LinkExt,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"
import {
  Layout,
  Main,
  Section,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import {
  ColorBar,
  HeroGradient,
  QuickLink,
  QuickLinks,
  SectionHeading,
  PrevArrow,
  NextArrow,
  VideoPlayer,
} from "../components/elements"

const FamilyAdvisoryCouncil = ({ data, pageContext }) => {
  const page = data.datoCmsFamilyAdvisoryCouncil
  const settings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  }
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout>
      <HelmetDatoCms seo={page?.seoMetaTags} />
      <Main>
        <Hero>
          <BackgroundImage Tag="div" fluid={page.heroImage.fluid}>
            <HeroGradient>
              <Container
                sx={{
                  px: "1em",
                  py: [6, 6, 7],
                  zIndex: 10,
                  position: "relative",
                }}
              >
                <Styled.h1>{page.title}</Styled.h1>
                <Breadcrumb
                  crumbs={crumbs}
                  crumbSeparator=" / "
                  crumbLabel={page.title}
                />
              </Container>
              <ColorBar
                sx={{ position: "absolute", bottom: 0, left: 0, zIndex: 20 }}
              />
            </HeroGradient>
          </BackgroundImage>
        </Hero>

        {/* IntroContent */}
        <Section id="intro">
          <Container sx={{ px: "1em" }}>
            <Flex sx={{ flexWrap: "wrap" }}>
              <Box sx={{ width: ["full", "full", "4/5"] }}>
                <Styled.h6 as="p">{page.introContent}</Styled.h6>
              </Box>
              <Text
                variant="styles.twoCols"
                sx={{ width: ["full"] }}
                dangerouslySetInnerHTML={{
                  __html: page.contentNode.childMarkdownRemark.html,
                }}
              />
            </Flex>

            <Box mt={4} sx={{ textAlign: "center" }}>
              <LinkExt to="https://wvuhealthcare.wufoo.com/forms/zlogonl1n7l7jf/">
                <Button as="span" bg="children.1">
                  Apply Now
                </Button>
              </LinkExt>
            </Box>
          </Container>
        </Section>

        {page.images.length > 0 ? (
          <Section id="gallery">
            <Container>
              <Flex
                sx={{
                  flexWrap: "wrap",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <Box sx={{ width: ["full"] }}>
                  <Slider {...settings} sx={{ color: "white" }}>
                    {page.images.map(img => (
                      <img src={img.url} alt=" " key={img.url} />
                    ))}
                  </Slider>
                </Box>
              </Flex>
            </Container>
          </Section>
        ) : null}

        {page.video && (
          <Section>
            <Container>
              <Box>
                <VideoPlayer url={page.video.url} />
              </Box>
            </Container>
          </Section>
        )}

        {/* FAQs */}
        <Section id="faqs" sx={{ backgroundColor: ["warmgray"] }}>
          <Container sx={{ px: "1em" }}>
            <Box sx={{ width: "full", textAlign: "center", mb: 4 }}>
              <SectionHeading>FAQs</SectionHeading>
            </Box>

            <Accordion
              collapsible
              multiple
              sx={{
                width: ["full", "full", "75%"],
                mx: "auto",
              }}
            >
              {page?.faqs?.map(({ ...faq }) => (
                <AccordionItem
                  key={faq?.id}
                  sx={{
                    backgroundColor: "white",
                    borderColor: "white",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "2px",
                    m: 0,
                    p: 0,
                    width: ["full"],
                  }}
                >
                  <Styled.h3 sx={{ my: 0 }}>
                    <AccordionButton
                      sx={{
                        alignItems: "center",
                        border: "none",
                        cursor: "pointer",
                        display: "flex",
                        fontSize: "inherit",
                        fontFamily: "inherit",
                        justifyContent: "space-between",
                        py: 2,
                        px: 3,
                        textAlign: "left",
                        width: "full",
                        '&[aria-expanded="true"]': {
                          svg: {
                            transform: "rotate(180deg)",
                            transitionProperty: "transform",
                            transitionDuration: "0.15s",
                          },
                        },
                        "&:hover": {
                          backgroundColor: "gray.3",
                          "svg.fa-chevron-down": {
                            transform: "translateY(5px)",
                            transitionProperty: "transform",
                            transitionDuration: "0.15s",
                          },
                        },
                      }}
                    >
                      {faq?.question}
                      <FontAwesomeIcon icon={faChevronDown} />
                    </AccordionButton>
                  </Styled.h3>
                  <AccordionPanel
                    sx={{
                      bg: "babyblue",
                      py: [3],
                      px: [2, 3],
                    }}
                  >
                    <Text
                      sx={{ width: ["full"] }}
                      dangerouslySetInnerHTML={{
                        __html: faq.answerNode.childMarkdownRemark.html,
                      }}
                    />
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </Container>
        </Section>

        {/* Resources */}
        <QuickLinks title="resources">
          {page.resources.map(
            ({ id, linkTitle, internalLinkUrl, extrenalLinkUrl }) => (
              <QuickLink
                key={id}
                to={extrenalLinkUrl || internalLinkUrl}
                title={linkTitle}
              />
            )
          )}
        </QuickLinks>
      </Main>
    </Layout>
  )
}

export default FamilyAdvisoryCouncil

export const query = graphql`
  {
    datoCmsFamilyAdvisoryCouncil {
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      introContent
      slug
      title
      heroImage {
        fluid(maxWidth: 1600, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      images {
        url
        fluid(maxWidth: 800, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      video {
        url
      }
      faqs {
        id
        question
        answer
        answerNode {
          childMarkdownRemark {
            html
          }
        }
      }
      resources {
        extrenalLinkUrl
        id
        internalLinkUrl
        linkTitle
      }
      seoMetaTags {
        tags
      }
    }
  }
`
